<template>
    <v-container>
        <v-navigation-drawer
        dark
        temporary
        fixed
        v-model="$store.state.sideNav">
            <v-list nav>
                <v-list-item
                link
                v-for="item of navItems"
                :key="item.id"
                :to="item.link">
                    <v-list-item-icon><v-icon>{{ item.icon }}</v-icon></v-list-item-icon>
                    <v-list-item-title>{{ item.description }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </v-container>
</template>
<script>
export default {
    name: 'SideNavigation',
    data() {
        return {
            navItems: [
                {
                    id: 0,
                    description: "Home",
                    icon: "mdi-home",
                    link: "/",
                },
                {
                    id: 1,
                    description: "Weekly",
                    icon: "mdi-calendar-week",
                    link: "/goals/weekly",
                },
                {
                    id: 2,
                    description: "Monthly",
                    icon: "mdi-calendar-month",
                    link: "/goals/monthly",
                },
                {
                    id: 3,
                    description: "Quaterly",
                    icon: "mdi-music-note-quarter",
                    link: "/goals/quarterly",
                },
                {
                    id: 4,
                    description: "Yearly",
                    icon: "mdi-umbrella-beach",
                    link: "/goals/yearly",
                },
                {
                    id: 5,
                    description: "Analytics",
                    icon: "mdi-poll",
                    link: "/analytics",
                },
                {
                    id: 6,
                    description: "Settings",
                    icon: "mdi-cog",
                    link: "/settings",
                },
            ],
        };
    },
}
</script>
<style lang="scss" scoped>
.v-list {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);

    .v-list-item--active {
        background: #00acee;
    }
}
</style>