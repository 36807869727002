<template>
    <v-dialog
    v-model="$store.state.dialog">
        <v-card class="pa-4">
            <v-card-title class="blue--text justify-center mb-6">Create new goal</v-card-title>
            <v-card-text>
                <v-form>
                    <v-select
                    :items="goalTypes"
                    required
                    label="Select goal type"
                    outlined
                    v-model="goalType"
                    ></v-select>
                    <v-text-field
                    label="Goal title"
                    required
                    outlined
                    v-model="goalTitle"
                    rows="1">
                    </v-text-field>
                    <v-row class="mx-0 mt-4 mb-1">
                        <ErrorBtn @click.native="hideDialog()" />
                        <v-spacer></v-spacer>
                        <PrimaryBtn @click.native="createGoal()" />
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import ErrorBtn from '../common/ErrorBtn.vue';
import PrimaryBtn from '../common/PrimaryBtn.vue';
import { mapActions } from 'vuex';

export default {
    name: 'CreateGoal',
    components: {
        ErrorBtn,
        PrimaryBtn,
    },
    data() {
        return {
            goalTypes: [
                "Weekly",
                "Monthly",
                "Quarterly",
                "Yearly",
            ],
            goalType: "",
            goalTitle: "",
        };
    },
    methods: {
        ...mapActions([
            'hideDialog',
            'showSnackbar',
            'setSnackbarMessage',
        ]),
        createGoal() {
            const ID = this.$store.state.goals.length;
            const GOAL_TYPE = this.goalType.toLowerCase();
            const GOAL_TITLE = this.goalTitle;
            const GOAL = {
                id: ID,
                type: GOAL_TYPE,
                title: GOAL_TITLE,
            };
            const MESSAGE = "The goal has been created successfully!";
            this.setSnackbarMessage(MESSAGE);
            this.showSnackbar();
            this.$store.dispatch('createGoal', GOAL);
            this.hideDialog();

            return true;
        },
    },
}
</script>