<template>
    <v-app-bar
    dark
    dense
    app>
        <v-app-bar-nav-icon
        @click="showSideNav()">
        </v-app-bar-nav-icon>
        <AppLogo />
        <v-app-bar-title class="ml-3">Sigma Goal Tracker</v-app-bar-title>
    </v-app-bar>
</template>
<script>
import AppLogo from './components/Logo.vue';
import { mapActions } from 'vuex'

export default {
    name: 'AppBar',
    components: {
        AppLogo,
    },
    methods: {
        ...mapActions([
            'showSideNav',
        ]),
    },
}
</script>
<style lang="scss" scoped>
.v-app-bar-title {
    font-family: fantasy;
}
</style>