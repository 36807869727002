<template>
    <v-snackbar
    v-model="$store.state.snackbar"
    color="success"
    top
    right>
        {{ $store.state.snackbarMessage }}
    </v-snackbar>
</template>
<script>
export default {
    name: 'SnackBar',
}
</script>