<template>
    <v-container>
        <AppBar />
        <SideNavigation />
    </v-container>
</template>
<script>
import AppBar from './components/app bar/AppBar.vue';
import SideNavigation from './components/SideNavigation.vue';

export default {
    name: 'AppHeader',
    components: {
        AppBar,
        SideNavigation,
    },
}
</script>