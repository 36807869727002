<template>
    <v-img src="../../../../../assets/logo.jpg"
    max-height="50"
    max-width="50"
    contain></v-img>
</template>
<script>
export default {
    name: 'AppLogo',
}
</script>