<template>
    <v-btn 
    rounded
    text
    color="error">
        Cancel
    </v-btn>
</template>
<script>
export default {
    name: 'ErrorBtn',
}
</script>