<template>
  <v-container>
    <v-btn
    rounded
    color="primary"
    right
    absolute
    @click="showDialog()">
      Add goal
    </v-btn>
    <CreateGoal />
  </v-container>
</template>
<script>
import CreateGoal from '../create goal/CreateGoal.vue';
import { mapActions } from 'vuex';

export default {
  name: 'Home',
  data() {
    return {
    };
  },
  components: {
    CreateGoal,
  },
  methods: {
    ...mapActions([
      'showDialog',
    ]),
  },
}
</script>
