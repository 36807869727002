<template>
    <v-overlay
    opacity="1"
    z-index="8"
    v-model="$store.state.loader">
        <v-progress-circular indeterminate color="primary" size="80"></v-progress-circular>
    </v-overlay>
</template>
<script>
export default {
    name: 'CircularLoader',
}
</script>