<template>
  <v-app>
    <AuxiliaryComps />
    <AppHeader />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
import AuxiliaryComps from './components/auxiliary/Auxiliary.vue';
import AppHeader from './components/header/Header.vue';

export default {
  name: 'App',
  components: {
    AuxiliaryComps,
    AppHeader,
  },
}
</script>
<style scoped>
#app {
  background: #eee;
}
</style>