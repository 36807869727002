<template>
    <v-card width="90%" class="mx-auto">
        <v-card-title class="blue--text">{{ getGoalType }} Goals</v-card-title>
        <v-card-text>
            <v-list>
                <v-list-item
                v-for="goal of getGoals"
                :key="goal.id">
                    <v-list-item-title>{{ goal.title }}</v-list-item-title>
                    <v-list-item-action>
                        <v-btn
                        fab
                        icon
                        color="error"
                        @click="deleteGoal(goal.id)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    name: 'Goals',
    data() {
        return {
            goals: [],
        };
    },
    methods: {
        ...mapActions([
            'showSnackbar',
            'setSnackbarMessage',
        ]),
        deleteGoal(id) {
            this.goals = this.goals.filter(goal => goal.id != id);
            let type = this.$route.params.type;
            this.$store.dispatch('deleteGoal', { id, type });
            const MESSAGE = "The goal has been deleted successfully!";
            this.setSnackbarMessage(MESSAGE);
            this.showSnackbar();

            return true;
        },
    },
    computed: {
        getGoals() {
            const TYPE = this.$route.params.type;
            const goals = this.goals.filter((goal) => goal.type == TYPE);

            return goals;
        },
        getGoalType() {
            let goalType = this.$route.params.type;
            let title = goalType.charAt(0).toUpperCase() + goalType.slice(1);

            return title;
        },
    },
    created() {
        this.goals = this.$store.state.goals;
    },
}
</script>