import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loader: true,
    snackbar: false,
    sideNav: false,
    dialog: false,
    goals: [],
  },
  getters: {
  },
  mutations: {
    SHOW_LOADER(state) {
      state.loader = true;
    },
    HIDE_LOADER(state) {
      state.loader = false;
    },
    SHOW_SNACKBAR(state) {
      state.snackbar = true;
    },
    HIDE_SNACKBAR(state) {
      state.snackbar = false;
    },
    SET_SNACKBAR_MESSAGE(state, message) {
      state.snackbarMessage = message;
    },
    SHOW_SIDE_NAV(state) {
      state.sideNav = true;
    },
    SHOW_DIALOG(state) {
      state.dialog = true;
    },
    HIDE_DIALOG(state) {
      state.dialog = false;
    },
    CREATE_GOAL(state, goal) {
      state.goals.push(goal);
    },
    DELETE_GOAL(state, { id, type }) {
      state.goals = state.goals.filter((goal) => {
        console.log(type, goal.type);
        if (goal.type === type) {
          return goal.id != id;
        } else {
          return;
        }
      });
    },
  },
  actions: {
    showLoader(context) {
      context.commit('SHOW_LOADER');
    },
    hideLoader(context) {
      context.commit('HIDE_LOADER');
    },
    showSnackbar(context) {
      context.commit('SHOW_SNACKBAR');
    },
    hideSnackbar(context) {
      context.commit('HIDE_SNACKBAR');
    },
    setSnackbarMessage(context, message) {
      context.commit('SET_SNACKBAR_MESSAGE', message);
    },
    showSideNav(context) {
      context.commit('SHOW_SIDE_NAV');
    },
    showDialog(context) {
      context.commit('SHOW_DIALOG');
    },
    hideDialog(context) {
      context.commit('HIDE_DIALOG');
    },
    createGoal(context, goal) {
      context.commit('CREATE_GOAL', goal);
    },
    deleteGoal(context, { id, type }) {
      context.commit('DELETE_GOAL', { id, type });
    },
  },
  modules: {
  }
})
