<template>
    <v-container>
        <SnackBar />
        <CircularLoader />
    </v-container>
</template>
<script>
import SnackBar from './components/Snackbar.vue';
import CircularLoader from './components/CircularLoader.vue';

export default {
    name: 'AuxiliaryComps',
    components: {
        SnackBar,
        CircularLoader,
    },
}
</script>