import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/home/Home.vue';
import Goals from '../components/goals/Goals.vue';
import Analytics from '../components/analytics/Analytics.vue';
import Settings from '../components/settings/Settings.vue';
import store from '../store/index.js';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/goals/:type',
    name: 'goals',
    component: Goals,
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: Analytics,
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('showLoader');
  setTimeout(() => {
    next();
  }, 500);
});

router.afterEach(() => {
  setTimeout(() => {
    store.dispatch('hideLoader');
  }, 500);
});

export default router
