<template>
    <v-btn 
    rounded
    color="primary">
        Create
    </v-btn>
</template>
<script>
export default {
    name: 'PrimaryBtn',
}
</script>